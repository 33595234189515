<template>
  <v-container>
    <v-sheet rounded="lg" class="pa-4">
      <h1 class="mb-6">Descargar Reportes</h1>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="mb-3"
          v-for="(report, index) in reports"
          :key="index"
        >
          <v-card class="pa-5">
            <h3 class="mb-4">{{ report.title }}</h3>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-if="report.dateFilter"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="button mb-4"
                  outlined
                  large
                  block
                >
                  <v-icon size="20" class="mr-1">mdi-calendar-outline</v-icon>
                  <span class="date-button-text">{{
                    dateRangeText(report.date)
                  }}</span>
                </v-btn>
              </template>
              <v-date-picker
                v-model="report.date"
                range
                locale="es"
                no-title
              ></v-date-picker>
            </v-menu>

            <v-select
              v-model="report.organizatorType"
              :items="organizatorTypeOptions"
              outlined
              dense
              hide-details="auto"
              class="mb-4"
              v-if="report.organizatorTypeFilter"
            ></v-select>

            <v-select
              v-model="report.description"
              :items="councilDescriptions"
              placeholder="Tipo de Consejo"
              outlined
              dense
              hide-details="auto"
              class="mb-4"
              v-if="report.descriptionFilter"
            ></v-select>

            <v-select
              v-model="report.committee"
              :items="committeeDescriptions"
              placeholder="Tipo de Consejo"
              outlined
              dense
              hide-details="auto"
              class="mb-4"
              v-if="report.committeeFilter"
            ></v-select>

            <v-autocomplete
              v-model="report.person"
              :items="members"
              :loading="isLoadingAutocomplete"
              :search-input.sync="search"
              item-text="name"
              item-value="_id"
              v-if="report.personFilter"
            ></v-autocomplete>

            <v-btn
              v-bind="attrs"
              v-on="on"
              class="button mt-6"
              color="#1b273e"
              large
              block
              :disabled="report.personFilter == true && report.person == ''"
              @click="downloadReport(report)"
            >
              <span class="white--text">Descargar</span>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  data() {
    return {
      dateMenu: false,
      organizatorTypeOptions: ["Local", "Nacional"],
      isLoadingAutocomplete: false,
      councilDescriptions: [],
      committeeDescriptions: [],
      members: [],
      search: "",
      reports: [
        {
          id: 1,
          title: "Reporte de Eventos",
          dateFilter: true,
          personFilter: false,
          groupFilter: true,
          organizatorTypeFilter: true,
          descriptionFilter: false,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          url: "events",
        },
        {
          id: 2,
          title: "Reporte de Contribuciones",
          dateFilter: true,
          personFilter: false,
          groupFilter: false,
          organizatorTypeFilter: false,
          descriptionFilter: false,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          url: "contributions",
        },
        {
          id: 3,
          title: "Reporte de Consejos",
          dateFilter: true,
          personFilter: false,
          groupFilter: false,
          organizatorTypeFilter: false,
          descriptionFilter: true,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          url: "councils",
        },
        {
          id: 4,
          title: "Reporte de Comités",
          dateFilter: true,
          personFilter: false,
          groupFilter: false,
          organizatorTypeFilter: false,
          descriptionFilter: false,
          committeeFilter: true,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          committee: "",
          url: "committees",
        },
        {
          id: 5,
          title: "Reporte de Puntos EPC por socio",
          dateFilter: true,
          personFilter: true,
          groupFilter: false,
          organizatorTypeFilter: false,
          descriptionFilter: false,
          committeeFilter: false,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          committee: "",
          url: "epc-points",
        },
        {
          id: 6,
          title: "Reporte de Antiguedad",
          dateFilter: false,
          personFilter: false,
          groupFilter: false,
          organizatorTypeFilter: false,
          descriptionFilter: false,
          committeeFilter: false,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          committee: "",
          url: "seniority",
        },
        {
          id: 7,
          title: "Reporte de Movimientos Generales",
          dateFilter: true,
          personFilter: false,
          groupFilter: false,
          organizatorTypeFilter: false,
          descriptionFilter: false,
          committeeFilter: false,
          date: ["2024-01-01", "2025-01-01"],
          person: "",
          group: "",
          organizatorType: "Local",
          description: "",
          committee: "",
          url: "general-movements",
        },
      ],
    };
  },

  watch: {
      async search (val) {
        // Items have already been requested
        if (this.isLoadingAutocomplete) return

        try {
        this.isLoadingAutocomplete = true;
        
        const response = await this.axios({
          method: "POST",
          url: "/movements/members",
          data: {
            name: this.search,
          },
        });

        this.members = response.data.members;

        for(let member of this.members) {
          member.name = `${member.name} ${member.firstLastname}`;
        }
      } catch (error) {
        console.error("Error buscando miembros:", error);
      } finally {
        this.isLoadingAutocomplete = false;
      }
      },
    },

  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    dateRangeText(dateArray) {
      return dateArray.join(" ~ ");
    },

    // Implementa las funciones de descarga de reportes según sea necesario
    async downloadReport(report) {
      try {
        this.loading();

        const response = await this.axios({
          method: "POST",
          url: `/movements/${report.url}`,
          data: {
            dateRange: report.date,
            organizatorType: report.organizatorType,
            description: report.description,
            committee: report.committee,
            member: report.person,
          },
        });

        console.log("Descarga exitosa:", response.data.report);

        const ws = XLSX.utils.json_to_sheet(response.data.report);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
        XLSX.writeFile(wb, `${report.title}.xlsx`);
      } catch (error) {
        console.error("Error descargando reporte:", error);
      } finally {
        this.loaded();
      }
    },

    async fetchCouncilDescriptions() {
      try {
        const response = await this.axios({
          method: "GET",
          url: "/movements/councils/descriptions",
        });

        this.councilDescriptions = response.data.descriptions;
      } catch (error) {
        console.error("Error obteniendo descripciones de consejos:", error);
      }
    },

    async fetchCommitteeDescriptions() {
      try {
        const response = await this.axios({
          method: "GET",
          url: "/movements/committees/descriptions",
        });

        this.committeeDescriptions = response.data.descriptions;
      } catch (error) {
        console.error("Error obteniendo descripciones de consejos:", error);
      }
    },

    async searchMembers() {
      try {
        this.isLoadingAutocomplete = true;
        
        const response = await this.axios({
          method: "POST",
          url: "/movements/members",
          data: {
            name: this.search,
          },
        });

        this.members = response.data.members;
      } catch (error) {
        console.error("Error buscando miembros:", error);
      } finally {
        this.isLoadingAutocomplete = false;
      }
    },
  },

  async created() {
    this.fetchCouncilDescriptions();
    this.fetchCommitteeDescriptions();
    this.searchMembers(); // Agrega esta línea para buscar miembros al cargar la página
  },
};
</script>

<style scoped>
.date-button-text {
  white-space: nowrap;
  display: inline-flex;
  text-transform: capitalize !important;
}
.v-btn--block {
  padding: 0 0 !important;
}
.v-text-field--outlined {
  border: thin solid #353434 !important;
}
</style>